import React, { useState, useEffect, useRef } from 'react';
import {Container, Row, Col, Button, Card, Small, Form, InputGroup, Tooltip, OverlayTrigger} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/styles.css'
import { Helmet } from 'react-helmet';
import UprightNav from '../components/navbar';
import { useLocation, useNavigate } from 'react-router-dom';
import foodPic from '../assets/img/food-pic.jpg';
import {AddressAutofill, SearchBox} from "@mapbox/search-js-react";
import {GeoAltFill, InfoCircle, TagFill} from "react-bootstrap-icons";
import TiktokPixel from "tiktok-pixel";

function RestaurantResultsPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const [restaurantData, setRestaurantData] = useState([]);
    let deliveryAddress = localStorage.getItem('deliveryAddress');
    let deliveryCoordinates = localStorage.getItem('deliveryCoordinates');
    const [manualAddressInput, setManualAddressInput] = useState('');
    const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const [selectedCuisine, setSelectedCuisine] = useState(location.state?.selectedCuisine || '');
    const [noRestaurantsFound, setNoRestaurantsFound] = useState(false);
    const [queryRun, setQueryRun] = useState(false);
    const [usingGeolocation, setUsingGeolocation] = useState(false);
    const elementRefs = useRef([]);




    TiktokPixel.pageView(); // For tracking page view
    TiktokPixel.track("Search", {query: deliveryAddress ? deliveryAddress : deliveryCoordinates}); // For tracking default events. More info about standard events: https://ads.tiktok.com/help/article?aid=10028


    const handleSuggestionClick = (deliveryAddress, deliveryCoordinates ) => {
        //set  localstoreage for delivery address
        localStorage.setItem('deliveryAddress', deliveryAddress);
        //set localstorage for delivery coordinates
        localStorage.setItem('deliveryCoordinates', JSON.stringify(deliveryCoordinates));
    };

    const handleDropdownChange = (e) => {
        setSelectedCuisine(e.target.value);
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
           Order directly from select restaurants' websites and have Upright Bites handle the delivery.
        </Tooltip>
    );

    const filteredRestaurants = selectedCuisine === ''
        ? restaurantData
        : restaurantData.filter(restaurant => restaurant.cuisine === selectedCuisine);

    const handleSearchClick = (e) => {
        e.preventDefault();
        localStorage.setItem('deliveryCoordinates', []);
        localStorage.setItem('deliveryAddress', manualAddressInput);
        navigate('/order')

    };

    const handleManualAddressInput = (e) => {
        setManualAddressInput(e);
    };

     const handleGeoButtonClick = () => {
        localStorage.removeItem('deliveryAddress');
        localStorage.removeItem('deliveryCoordinates')
         document.getElementById('delivery-address').value = '';
        navigate('/order')
     };

    // const handleSelect = (data) => {
    //     const suggestionResults = data.features
    //     // retrieve address from suggestionResults
    //     const deliveryAddress = suggestionResults[0].properties.full_address
    //     console.log(deliveryAddress)
    //     const deliveryCoordinates = suggestionResults[0].geometry.coordinates
    //     console.log(deliveryCoordinates)
    //     console.log('Delivery address', deliveryAddress, 'Delivery coordinates', deliveryCoordinates);
    //     handleSuggestionClick(deliveryAddress, deliveryCoordinates)
    //
    // };

    const handleSelect = (data) => {
        console.log(data)
        const selectedAddress = data.features[0].properties.full_address
        // retrieve address from suggestionResults
        localStorage.setItem('deliveryAddress', selectedAddress)
        const selectedCoords = data.features[0].geometry.coordinates
        localStorage.setItem('deliveryCoordinates',JSON.stringify(selectedCoords))
        console.log(deliveryCoordinates)
        console.log('Delivery address', deliveryAddress, 'Delivery coordinates', deliveryCoordinates);
        navigate('/order')
    };

    const handleCardClick = (restaurant, index) => {
        // Use the useNavigate hook to navigate to the desired page
        window.umami.track('Restaurant Card Click', {restaurantId: restaurant.restaurantId, index: index});
        navigate(`/menu/${restaurant.restaurantId}`);
    };  

    const renderRestaurants = () => {
        const restaurantsToRender = (queryRun === true && noRestaurantsFound === false && filteredRestaurants.length === 0) ? restaurantData : filteredRestaurants;
        return restaurantsToRender.map((restaurant, index) => (
            <Col xs={12} md={6} key={index} className="mb-4">
                <Card style={{width: '100%', cursor: 'pointer'}} onClick={() => handleCardClick(restaurant, index)}
                    data-index={index}
                    ref={el => elementRefs.current[index] = el}
                    >
                    <div className="position-relative">
                    <Card.Img variant="top" className="img-fluid w-100"  style={{ maxHeight: '422.36px' }} src={restaurant.cardimage}/>
                        {restaurant.directdine === true &&
                            <>
                                <OverlayTrigger
                                    placement={"top"}
                                    overlay={renderTooltip}
                                >
                                    <span className="badge rounded-pill text-bg-primary position-absolute bottom-0 start-0 mb-2 ms-2">
                                        Direct Dine
                                    </span>
                                </OverlayTrigger>
                            </>
                        }
                    </div>
                    <Card.Body>
                        <Card.Title>{restaurant.name}
                            <br/>
                            <div className="text-muted">{restaurant.cuisine}</div>
                        </Card.Title>
                        <Card.Text>
                            {restaurant.address1}{restaurant.address2 ? `, ${restaurant.address2}` : ''}, {restaurant.city}, {restaurant.state} {restaurant.zipcode}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        ));
    };

    const handleResultsErrors = () => {
        if(noRestaurantsFound === true){
            return(
                <Col xs={12}><div className="alert alert-danger" role="alert"><h3 className="text-center">We don't have any restaurants serving your address yet 😢 </h3><p className="text-center"> We're still growing, please check back soon</p></div></Col>
            )}
        if(!deliveryCoordinates && !deliveryAddress && !manualAddressInput && restaurantData.length === 0){
            return(
        <Col xs={12}><div className="alert alert-primary text-center" role="alert">Please enter your delivery address or allow access to your location.</div></Col>
            )}
        while(usingGeolocation === true && !deliveryCoordinates){
            return(
                <Col xs={12}>
                    <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div>
                </Col>
            )
            }
        if (queryRun === true && noRestaurantsFound === false && filteredRestaurants.length === 0){
            return(
                <Col xs={12}><div className="alert alert-danger" role="alert"><h3 className="text-center">We don't have any {selectedCuisine} restaurants serving your address yet 😢 </h3><p className="text-center">But we've got some other great options below!</p></div></Col>
            )}
        if (queryRun === false && (deliveryAddress || deliveryCoordinates) && restaurantData.length === 0){
            return(
                <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div>
            )
        }

}

// Add any other logic or return statement if needed};

// const fetchData = () => {
    //     try {
    //         const data = fetchRestaurantData(deliveryAddress, deliveryCoordinates);
    //         setRestaurantData(data);
    //         console.log(restaurantData)
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //         // Handle error as needed
    //     }
    // };

    async function handleCoordinateLogic() {
        const conditionsMet = () => deliveryCoordinates || deliveryAddress;
        while (!conditionsMet()) {
            await new Promise(resolve => setTimeout(resolve, 500)); // Retry every second
            if (navigator.geolocation) {
                try {
                    const position = await new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(resolve, reject)
                    });
                    setUsingGeolocation(true);
                    const { latitude, longitude } = position.coords;
                    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
                    localStorage.setItem('deliveryCoordinates', JSON.stringify([longitude, latitude]));
                    return [longitude, latitude];
                } catch (error) {
                    console.log("Error getting location:", error);
                }
            } else {
                console.log("Geolocation not supported");
            }
        }
        console.log('Conditions met');
// return deliveryCoordinates and format as a double array
        if (deliveryCoordinates) {
        return JSON.parse(deliveryCoordinates);
    }
    }

    useEffect(() => {
        const fetchData = async () => {
            const deliveryCoordinates = await handleCoordinateLogic();
            const formattedReqBody = {
                address: deliveryAddress,
                coordinates: deliveryCoordinates,
            };
            console.log(formattedReqBody)
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVICES_DOMAIN}/restaurant/restaurant-results`, {
                    method: 'POST',
                    headers: {
                        'User-Agent': 'UprightBites',
                        'Host': 'localhost',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formattedReqBody),
                    });

                const data = await response.json();
                if (data.length === 0) {
                    setNoRestaurantsFound(true);
                }
                else {
                    setNoRestaurantsFound(false);
                };
                setQueryRun(true);
                setRestaurantData(data);
                window.umami.track('Restaurant Results', {length: data.length});
            } catch (error) {
                console.error('Error fetching restaurant data:', error);
                throw error; // Rethrow the error to be caught by the higher-level error handling
            }
        };

        fetchData();
    }, [deliveryAddress, deliveryCoordinates]);

    useEffect(() => {
        const trackedIndices = new Set();
    
        const handlePageLoad = () => {
            const images = Array.from(document.images);
            const imageLoadPromises = images.map(img => {
                if (img.complete) {
                    return Promise.resolve();
                } else {
                    return new Promise(resolve => {
                        img.onload = img.onerror = resolve;
                    });
                }
            });
    
            Promise.all(imageLoadPromises).then(() => {
                const observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            const index = entry.target.getAttribute('data-index');
                            if (index !== null && !trackedIndices.has(index)) {
                                trackedIndices.add(index);
                                if (window.umami) {
                                    window.umami.track('Restaurant card view', { index: parseInt(index, 10) });
                                }
                            }
                        }
                    });
                }, { threshold: 0.2 });
    
                elementRefs.current.forEach(ref => {
                    if (ref) observer.observe(ref);
                });
    
                return () => {
                    elementRefs.current.forEach(ref => {
                        if (ref) observer.unobserve(ref);
                    });
                };
            });
        };
    
        handlePageLoad();
    }, [filteredRestaurants]);

    return (
        <Container>
            <Helmet>
                <title>Upright Bites: Restaurants</title>
            </Helmet>
            <Row className="mt-3 align-items-center">
                <Col xs={12}><p className="text-center">Deliver to:</p>
                </Col>
            </Row>
            {/*<Form id="deliveryAddressForm" onSubmit={handleSearchClick}>*/}
            {/*    <Row className="justify-content-center d-flex align-items-center">*/}
            {/*        <Col xl={6} lg={8} xs={12}>*/}
            {/*            <SearchBox*/}
            {/*                accessToken={accessToken}*/}
            {/*                name="deliveryAddress"*/}
            {/*                placeholder={queryRun === true && !deliveryAddress ? 'Current location' : deliveryAddress}*/}
            {/*                value={manualAddressInput}*/}
            {/*                onChange={handleManualAddressInput}*/}
            {/*                onRetrieve={handleSelect}*/}
            {/*                options={{*/}
            {/*                    language: 'en',*/}
            {/*                    country: 'US',*/}
            {/*                    proximity: 'ip',*/}
            {/*                    types: 'address',*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*</Form>*/}
            <Form id="deliveryAddressForm" onSubmit={handleSearchClick}>
                <Row className="justify-content-center">
                    <Col md={6} xs={12}>
                        <AddressAutofill
                            accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                            onRetrieve={(data) => handleSelect(data)}
                        >
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    id="delivery-address"
                                    onChange={(e) => (handleManualAddressInput(e.target.value))}
                                    placeholder={!deliveryAddress && deliveryCoordinates && usingGeolocation ? "Using current location" : "Delivery Address"}
                                    className={"mb-2"}
                                    defaultValue={deliveryAddress}
                                    data-umami-event={"Delivery Address Input"}
                                />
                                <Button className={"mb-2"} variant={"outline-secondary"} onClick={handleGeoButtonClick} data-umami-event={"Geolocation button"}><GeoAltFill
                                    size={20} alt={"Use current location"}/></Button>
                            </InputGroup>
                        </AddressAutofill>
                    </Col>
                    <Col md={1} xs={1}>
                        <Button type="submit" data-umami-event={"Search button"}>
                            Search
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Form>
                <Form.Group controlId="cuisineDropdown">
                    <Row className={'mt-3 d-flex align-items-center'}>
                        <Col xs="auto"> 
                            <Form.Label>Filters:</Form.Label>
                        </Col>
                        <Col md="5" xs="flex-grow-1">
                            <Form.Select value={selectedCuisine} onChange={handleDropdownChange} data-umami-event={"Filter menu"}>
                                <option value="">All Cuisines</option>
                                {Array.from(new Set(restaurantData.map(restaurant => restaurant.cuisine))).map((cuisine, index) => (
                                    <option key={index} value={cuisine}>{cuisine}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
            <div className={'position-relative'}>
            <span className="badge bg-success position-absolute top-0 end-0">
                        <TagFill/> 40% off your first order
            </span>
            </div>
            <Row className="mt-4">
                {handleResultsErrors()}
                {renderRestaurants()}
            </Row>
        </Container>
    );
}

export default RestaurantResultsPage;
