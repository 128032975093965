import React, { useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {useLocation, useNavigate} from "react-router-dom";
import UprightNav from "../components/navbar";
import {Container, Row, Col, FormGroup, Form, Button, ProgressBar, Table, Modal, Alert} from "react-bootstrap";
import {useShoppingCart} from "use-shopping-cart";
import {Helmet} from "react-helmet";
import { AddressAutofill} from "@mapbox/search-js-react";
import  parser  from 'parse-address';
import geoDistance from 'geo-distance-helper';
import {getAuth, onAuthStateChanged, signInAnonymously} from "firebase/auth";
import app from "../firebaseConfig";
import closedModal from "../components/closedModal";
import ClosedModal from "../components/closedModal";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const VerifyAddress = ({operatingStatus}) => {
    const storedAddressString = localStorage.getItem('deliveryAddress');
    const [storedCoords, setStoredCoords] = useState(localStorage.getItem('deliveryCoordinates'));
    const [geoCoords, setGeoCoords] = useState('');
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [tel, setTel] = useState('');
    const [telError, setTelError] = useState(false);
    const [deliveryInstructions, setDeliveryInstructions] = useState('');
    const [deliveryError, setDeliveryError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formValidationError, setFormValidationError] = useState(false)
    const {
        cartDetails
    } = useShoppingCart();
    const auth = getAuth(app);
    const [user, setUser] = useState(auth.currentUser || '');
    const location = useLocation();
    const currentPath = window.location.pathname;
    const inboundError = location.state?.error
    const [message, setMessage] = useState(location.state?.message) || '';



    useEffect(() => {
        if(storedAddressString){
        const parseAddress = parser.parseLocation(storedAddressString);
        setAddress1(parseAddress.number + ' ' + parseAddress.prefix + ' ' + parseAddress.street + ' ' + parseAddress.type);
        setCity(parseAddress.city);
        setState(parseAddress.state);
        setZip(parseAddress.zip);
        }}, []);

    useEffect(() => {
        const storedDeliveryInfo = JSON.parse(sessionStorage.getItem('deliveryInformation'));
        if (storedDeliveryInfo) {
            document.getElementById('first-name').value = storedDeliveryInfo.deliveryFirstName;
            document.getElementById('last-name').value = storedDeliveryInfo.deliveryLastName;
            document.getElementById('address1').value = storedDeliveryInfo.deliveryAddress1;
            document.getElementById('address2').value = storedDeliveryInfo.deliveryAddress2;
            document.getElementById('city').value = storedDeliveryInfo.deliveryCity;
            document.getElementById('state').value = storedDeliveryInfo.deliveryState;
            document.getElementById('zip').value = storedDeliveryInfo.deliveryZip;
            document.getElementById('deliver-instructions').value = storedDeliveryInfo.deliveryInstructions;
            setFirstName(storedDeliveryInfo.deliveryFirstName);
            setLastName(storedDeliveryInfo.deliveryLastName);
            setAddress1(storedDeliveryInfo.deliveryAddress1);
            setAddress2(storedDeliveryInfo.deliveryAddress2);
            setCity(storedDeliveryInfo.deliveryCity);
            setState(storedDeliveryInfo.deliveryState);
            setZip(storedDeliveryInfo.deliveryZip);
            setDeliveryInstructions(storedDeliveryInfo.deliveryInstructions);
            setEmail(storedDeliveryInfo.deliveryEmail);
            setTel(storedDeliveryInfo.deliveryTel);

        }
    }, []);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                signInAnonymously(auth).then((credential) => {
                    setUser(credential.user);
                });
            }
            if (user && !user.isAnonymous) {
                setEmail(user.email);
            }
            return () => unsubscribe();
        });
    }, [auth]);


    const validatePhoneNumber = (input_str) => {
        var phoneno = /^\+?(\d{1,3})?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if(input_str.match(phoneno)) {
            return true;
        }
        else {
            window.umami.track(`Phone number validation error`);
            return false;
        }
    }

    const validateEmail = (input_str) => {
        var email = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (input_str.match(email)) {
            return true;
        } else {
            window.umami.track(`Email validation error`);
            return false;
        }
    };

    const getCoords = async (address1, city, state, zip) => {
        try {
            let coordsLat, coordsLong;
            if(geoCoords) {
                console.log('Using geocoder coords')
                console.log(geoCoords)
                coordsLat = geoCoords.coordinates[1];
                coordsLong = geoCoords.coordinates[0];
                console.log('Using geocoder coords: ', coordsLat, coordsLong);
            }
            // Note that storedCoords are only set if user selected their address from the suggestions on the restaurant search, if they do a manual address entry the coords are calculated on the server so and are not sent back. This should probably be fixed sometime. You're welcome future person, maybe?
            else if(storedCoords) {
                console.log('using stored coords', storedCoords)
                //Stored coords are stored as [##,##], so we need to parse them into an array
                const storedCoordsObj = JSON.parse(storedCoords);
                console.log('new object', storedCoords)
                coordsLat = storedCoordsObj[1];
                coordsLong = storedCoordsObj[0];
                console.log('Using stored coords: ', coordsLat, coordsLong)
            }
             else {
                console.log('Using Mapbox coords')
                const response = await fetch('https://api.mapbox.com/geocoding/v5/mapbox.places/' + address1 + ' ' + city + ' ' + state + ' ' + zip + '.json?access_token=' + process.env.REACT_APP_MAPBOX_ACCESS_TOKEN)
                const coordsJson = await response.json();
                 coordsLat = coordsJson.features[0].center[1];
                 coordsLong = coordsJson.features[0].center[0];
                console.log('Using Mapbox coords: ', coordsLat, coordsLong)
            }
            const coords = {lat: coordsLat, lng: coordsLong};
            console.log('Coords are: ', coords);
            return coords;
        } catch (error) {
            console.error('Error fetching coords:', error);
        }
    }

    const verifyDeliveryZone = async () => {
        const custCoords = await getCoords(address1, city, state, zip);
        const restLat = Object.values(cartDetails)[0].product_metadata.restaurantLat;
        const restLong = Object.values(cartDetails)[0].product_metadata.restaurantLong;
        const restCoords = {lat: restLat, lng: restLong};
        console.log('Customer coords are: ', custCoords, 'Restaurant coords are: ', restCoords);
        const distance = await geoDistance(custCoords, restCoords);
        console.log('Distance is: ', distance)
        if(distance < 2.2) {
            return true;
        }
        else {
            window.umami.track(`Delivery zone error`);
            return false;
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            window.umami.track(`Required field validation error`);
            setFormValidationError(true);
        } else {
            const inDeliveryZone = await verifyDeliveryZone()
            if (inDeliveryZone === true) {
                sessionStorage.setItem('deliveryInformation', JSON.stringify({
                    deliveryFirstName: firstName,
                    deliveryLastName: lastName,
                    deliveryAddress1: address1,
                    deliveryAddress2: address2,
                    deliveryCity: city,
                    deliveryState: state,
                    deliveryZip: zip,
                    deliveryInstructions: deliveryInstructions,
                    deliveryTel: tel,
                    deliveryEmail: email
                }))
                navigate('/checkout/payment', {
                    state: {
                        deliveryInformation: {
                            deliveryFirstName: firstName,
                            deliveryLastName: lastName,
                            deliveryAddress1: address1,
                            deliveryAddress2: address2,
                            deliveryCity: city,
                            deliveryState: state,
                            deliveryZip: zip,
                            deliveryInstructions: deliveryInstructions,
                            deliveryTel: tel,
                            deliveryEmail: email
                        }
                    }
                });
            } else {
                setDeliveryError(true);
        }
    }
        setValidated(true);
    };

    const handleRetrieve = (data) => {
        const coords = data.features[0].geometry
        console.log('coords are: ', coords);
        setGeoCoords(coords);
    }



    return (
        <Container>
            <Helmet>
                <title>Upright Bites: Checkout</title>
            </Helmet>
            <ClosedModal operatingStatus={operatingStatus} />
        <Row className="d-flex align-items-center justify-content-center mt-2">
            {inboundError &&
                <Alert className={'mb-2'} variant="danger">{inboundError}</Alert>
            }
            {message &&
                <Alert className={'mt-3'} variant={'success'}>{message}</Alert>
            }
            <Col xl={4}>
                <h1>Checkout</h1>
            </Col>
            <Col xl={4}>
                <p className={'text-secondary mb-1'}>Your order from:</p>
                <div style={{cursor: 'pointer'}} onClick={() => navigate(`/menu/${Object.values(cartDetails)[0].product_metadata.restaurantId}`)} data-umami-event={"Restaurant info click"}>
                    <h6 className={'mb-1 ps-2'}>{Object.values(cartDetails)[0].product_metadata.restaurantName}</h6>
                    <p className="text-secondary mb-4 ps-2">{Object.values(cartDetails)[0].product_metadata.restaurantAddress1}{Object.values(cartDetails)[0].product_metadata.restaurantAddress2 ? `, ${Object.values(cartDetails)[0].product_metadata.restaurantAddress2}` : ''}, {Object.values(cartDetails)[0].product_metadata.restaurantCity} {Object.values(cartDetails)[0].product_metadata.restaurantState} {Object.values(cartDetails)[0].product_metadata.restaurantZipcode}</p>
                </div>
                </Col>
        </Row>
            <Row>
                <Col>
                    <Table className={"table"} style={{borderRadius: '15px', overflow: 'hidden'}}>
                    <tr style={{height: '3rem'}}>
                        <td className={"text-center text-white bg-secondary fw-bold"} style={{width: '50%'}}>Verify Address</td>
                        <td className={"text-center text-black bg-light"} style={{width: '50%'}}>Payment</td>
                    </tr>
                </Table>
            </Col>
        </Row>
        <Row>
            <Col>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group controlId="deliveryAddressConfirmation">
                        <Row>
                            <Col>
                                <Form.Label htmlFor="first-name">First name*</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="first-name"
                                    defaultValue={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    className={"mb-2"}
                                    data-umami-event={"First name field"}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">First name is required</Form.Control.Feedback>
                            </Col>
                            <Col>
                                <Form.Label htmlFor="last-name">Last name*</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="last-name"
                                    defaultValue={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    className={"mb-2"}
                                    data-umami-event={"Last name field"}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">Last name is required</Form.Control.Feedback>
                            </Col>
                        </Row>
                        <AddressAutofill
                            accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                            onRetrieve={(e) => handleRetrieve(e)}
                        >
                            <Form.Label htmlFor="address-line1">Address 1*</Form.Label>
                            <Form.Control
                                type="text"
                                id="address1"
                                autoComplete="address-line1"
                                defaultValue={address1}
                            onChange={(e) => {
                                    setAddress1(e.target.value);
                                    setStoredCoords('');
                                }}
                                className={"mb-2"}
                                data-umami-event={"Address 1 field"}
                                required />
                            <Form.Control.Feedback type="invalid">Address 1 is required</Form.Control.Feedback>
                            <Form.Label htmlFor="address2">Address 2</Form.Label>
                            <Form.Control
                                type="text"
                                id="address2"
                                autoComplete="address-line2"
                                defaultValue={address2}
                                onChange={(e) => setAddress2(e.target.value)}
                                className={"mb-2"}
                                data-umami-event={"Address 2 field"}
                            />
                            <Form.Label htmlFor="city">City*</Form.Label>
                            <Form.Control
                                type="text"
                                id="city"
                                autoComplete="address-level2"
                                defaultValue={city}
                                onChange={(e) => {
                                    setCity(e.target.value)
                                    setStoredCoords('');
                            }}
                                className={"mb-2"}
                                data-umami-event={"City field"}
                            required />
                            <Form.Control.Feedback type="invalid">City is required</Form.Control.Feedback>
                            <Form.Label htmlFor="state">State*</Form.Label>
                            <Form.Select
                                id="state"
                                autoComplete="address-level1"
                                value={state}
                                onChange={(e) => {
                                    setState(e.target.value);
                                    setStoredCoords('');
                                }}
                                className={"mb-2"}
                                data-umami-event={"State field"}
                                required
                            >
                                <option></option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">State is required</Form.Control.Feedback>
                            <Form.Label htmlFor="zip">Zip code*</Form.Label>
                            <Form.Control
                                type="text"
                                id="zip"
                                autoComplete="postal-code"
                                defaultValue={zip}
                                onChange={(e) => {
                                    setZip(e.target.value)
                                    setStoredCoords('');
                                }}
                                className={"mb-2"}
                                data-umami-event={"Zip code field"}
                                required
                            />
                            <Form.Control.Feedback type="invalid">Zip code is required</Form.Control.Feedback>
                            <Form.Label htmlFor="tel">Email address*</Form.Label>
                                <Form.Control
                                    type="email"
                                    id="email"
                                    defaultValue={email}
                                    onChange={(e) => {
                                        if (validateEmail(e.target.value)) {
                                            setEmail(e.target.value);
                                            setEmailError(false);
                                        } else {
                                            setEmailError(true);
                                        }
                                    }}
                                    className={"mb-2"}
                                    data-umami-event={"Email field"}
                                    required
                                    isInvalid={emailError}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {emailError ? "Please enter a valid email address" : "Email address is required"}
                                </Form.Control.Feedback>
                            <Form.Label htmlFor="tel">Phone number*</Form.Label>
                            <Form.Control
                                type="tel"
                                id="tel"
                                autoComplete="tel"
                                defaultValue={tel}
                                onChange={(e) => {
                                    if (validatePhoneNumber(e.target.value)) {
                                        setTel(e.target.value);
                                        setTelError(false);
                                    } else {
                                        setTelError(true);
                                    }
                                }}
                                className={"mb-2"}
                                data-umami-event={"Phone number field"}
                                required
                                isInvalid={telError}
                            />
                            <Form.Control.Feedback type="invalid">
                                {telError ? "Please enter a valid phone number" : "Phone number is required"}
                            </Form.Control.Feedback>
                        </AddressAutofill>
                        <Form.Label htmlFor="deliver-instructions">Delivery instructions</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            id="deliver-instructions"
                            defaultValue={deliveryInstructions}
                            onChange={(e) => setDeliveryInstructions(e.target.value)}
                            className={"mb-2"}
                            data-umami-event={"Delivery instructions field"}
                        />
                        <div className="d-grid gap-2 mb-3">
                            {formValidationError === true && validated && <div className="alert alert-danger" role="alert">Please
                                complete all required fields</div>}
            <Button variant="primary" type="submit" size="lg" data-umami-event={"Continue button"}>Continue to Payment</Button>
                        </div>
                    </Form.Group>
                </Form>
            </Col>
        </Row>

            <Modal show={deliveryError} onHide={() => setDeliveryError(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Something went wrong...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Either {Object.values(cartDetails)[0].product_metadata.restaurantName} doesn't deliver to that address or there was an error with your delivery address.</p>
                    <p>Please verify that your delivery address was entered correctly and try again.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeliveryError(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}


export default VerifyAddress;