import React, {useEffect, useId, useState} from 'react';
import {useShoppingCart} from 'use-shopping-cart';
import UprightNav from '../components/navbar'
import {useNavigate, Link} from "react-router-dom";
import {
    Container,
    Button,
    Tooltip,
    Overlay,
    Row,
    Col,
    ListGroup,
    Card,
    OverlayTrigger,
    InputGroup, Form
} from "react-bootstrap";
import {CartX, QuestionCircle, TagFill} from "react-bootstrap-icons";
import {getAuth, onAuthStateChanged, signInAnonymously} from "firebase/auth";
import app from "../firebaseConfig";
// import {useStripe, useElements, PaymentElement, loadStripe} from '@stripe/react-stripe-js';


const ShoppingCartPage = ({ operatingStatus }) => {
    const navigate = useNavigate();
    const {
        cartDetails,
        formattedTotalPrice,
        incrementItem,
        decrementItem,
        removeItem,
        setItemQuantity,
        totalPrice,
        cartCount
    } = useShoppingCart();
    const [IsTipValid, setIsTipValid] = useState(true);
    const [tip, setTip] = useState(sessionStorage?.getItem('tip'));
    let auth = getAuth(app);
    const [user, setUser] = useState(auth.currentUser);


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                signInAnonymously(auth).then((credential) => {
                    setUser(credential.user);
                });
            }
            return () => unsubscribe();
        }
    );
    }, [auth, user]);

    //cart math
    const firstOrderPromo = '40% off your first order';
    const firstOrderDiscount = 0.40;
    const deliveryFee = 0;
    const maxDiscount = 1000;
    const firstOrderDiscountAmount = Math.min(((totalPrice + deliveryFee) * firstOrderDiscount), maxDiscount);
    const discountedOrdersubtotal = ((totalPrice + deliveryFee + (tip * 100)) - firstOrderDiscountAmount);
    const fullPriceSubtotal = (totalPrice +  deliveryFee + (tip * 100));
    const firstOrderDiscountAmountFormatted = (firstOrderDiscountAmount * .01).toFixed(2);
    const discountedOrderSubtotalFormatted = (discountedOrdersubtotal * .01).toFixed(2);
    const fullPriceSubtotalFormatted = (fullPriceSubtotal * .01).toFixed(2);

    const handleCheckoutClick = () => {
        // Use the useNavigate hook to navigate to the desired page
        navigate(`/checkout`);
    };

    const handleIncrement = (item) => {
        incrementItem(item.id);
    };

    const handleDecrement = (item) => {
        decrementItem(item.id);
    };

    const handleRemove = (item) => {
        removeItem(item.id);
    };

    async function updateTip(event) {
        const value = event.target.value;
        const pattern = /^\$?\d{1,6}(\.\d{1,2})?$/; // Modified pattern
        if (pattern.test(value) || value === '') {
            let tipValue = value === '' ? 0 : parseFloat(value.replace('$', ''));
            setTip(tipValue);
            sessionStorage.setItem('tip', tipValue.toString());
            setIsTipValid(true);
        } else {
            setIsTipValid(false);
        }
    }

    return (
        <Container>
            {/* Header */}
            <Row className="d-flex align-items-center justify-content-center p-2">
                <Col xl={4}>
                    <h1>Shopping Cart</h1>
                </Col>
                <Col xl={4}>
                    {cartCount > 0 &&
                        <>
                        <p className={'text-secondary mb-1'}>Your order from:</p>
                        <div style={{cursor: 'pointer'}} onClick={() => navigate(`/menu/${Object.values(cartDetails)[0].product_metadata.restaurantId}`)} data-umami-event={"Restaurant info click"}>
                    <h6 className={'mb-1 ps-2'}>{Object.values(cartDetails)[0].product_metadata.restaurantName}</h6>
                                <p className="text-secondary mb-4 ps-2">{Object.values(cartDetails)[0].product_metadata.restaurantAddress1}{Object.values(cartDetails)[0].product_metadata.restaurantAddress2 ? `, ${Object.values(cartDetails)[0].product_metadata.restaurantAddress2}` : ''}, {Object.values(cartDetails)[0].product_metadata.restaurantCity} {Object.values(cartDetails)[0].product_metadata.restaurantState} {Object.values(cartDetails)[0].product_metadata.restaurantZipcode}</p>
                        </div>
                        </>
                    }
                </Col>
            </Row>
            {/* Shopping Cart and Summary */}
            <section className="bg-light my-0">
                <div className="container pt-4 pb-4">
                    {cartCount === 0 && 
                        <div className="alert alert-light text-center" role="alert"><p>Your shopping cart is empty.</p>
                            <p>Hungry? Checkout the amazing local <Link to={'/order'} data-umami-event={"Hungry link"}>restaurants serving
                                your neighborhood!</Link></p></div>
                    }
                    {cartCount > 0 &&
                        <Row>
                            <Col md={8} sm={12}>
                                <ListGroup flush className="g-3">
                                    {Object.keys(cartDetails).map((itemId) => {
                                            const item = cartDetails[itemId];
                                            return (
                                                <ListGroup.Item key={item.id}
                                                                className={"d-flex justify-content-between"}>
                                                    <Row className={"flex-fill"}>
                                                        <Col xs={6} lg={6} className="text-start">
                                                            <text className="fw-bold">{item.name}</text><br />
                                                            <text>{item.description}</text><br /><br />
                                                            <p className="text-muted">{item.product_metadata.specialInstructions}</p>
                                                        </Col>
                                                        <Col xs={6} lg={3} className="flex-fill align-items-center">
                                                            <select
                                                                style={{width: '100px'}}
                                                                className="form-select me-4"
                                                                value={item.quantity}
                                                                onChange={e => setItemQuantity(item.id, parseInt(e.target.value, 10))}
                                                                data-umami-event={"Quantity selected"}
                                                                >
                                                                <option value={1}>1</option>
                                                                <option value={2}>2</option>
                                                                <option value={3}>3</option>
                                                                <option value={4}>4</option>
                                                                <option value={5}>5</option>
                                                                <option value={6}>6</option>
                                                                <option value={7}>7</option>
                                                                <option value={8}>8</option>
                                                                <option value={9}>9</option>
                                                                <option value={10}>10</option>
                                                            </select>
                                                        </Col>
                                                        <Col xs={12} lg={3} className="flex-fill align-items-center">
                                                            <p>{item.formattedPrice}</p>
                                                        </Col>
                                                    </Row>
                                                    <button
                                                        onClick={() => handleRemove(item)}
                                                        className="btn btn-link"
                                                        data-umami-event={"Remove from cart"} 
                                                        >
                                                        <CartX size={25} color={"secondary"}/>
                                                    </button>
                                                </ListGroup.Item>
                                            )
                                        }
                                    )}
                                </ListGroup>
                                <div className="d-flex justify-content-center mt-3">
                                <Button variant="outline-secondary" onClick={() => navigate(`/menu/${Object.values(cartDetails)[0].product_metadata.restaurantId}`)}
                                    data-umami-event={"Add more items button"} 
                                    >
                                    Add more items
                                </Button>
                                </div>
                                </Col>
                            {/* Order Summary */}
                            <Col>
                                <Card className="p-3">
                                    {/* Display total price, discounts, tax, and final total */}
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-2">Items in cart:</p>
                                        <p className="mb-2">{formattedTotalPrice}</p>
                                    </div>
                                    {/* Removing delivery fee
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-2">Delivery fee:</p>
                                        <p className="mb-2">${(deliveryFee * 0.01).toFixed(2)}</p>
                                    </div> */}
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-2">
                                            Tip
                                            <div className={'text-secondary small'}>100% of your tip is paid to your courier
                                            </div>
                                        </p>
                                        <p className="mb-2">
                                            <InputGroup controlId="tipField">
                                                <InputGroup.Text>$</InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="0.00"
                                                    defaultValue={tip}
                                                    onChange={event => updateTip(event)}
                                                    isInvalid={IsTipValid === false}
                                                    data-umami-event={"Tip field selected"}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a number with up to 2 decimal places.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </p>
                                    </div>
                                    {user && user.isAnonymous &&
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-2 badge bg-success">
                                        <TagFill/> {firstOrderPromo}</p>
                                        <p className="mb-2">-${firstOrderDiscountAmountFormatted}</p>
                                    </div>
                                    }   
                                    <div className="d-flex justify-content-between">
                                        <p className="fw-bold mb-2">Subtotal:</p>
                                        {user && user.isAnonymous ?
                                        <p className="fw-bold 4">${discountedOrderSubtotalFormatted}</p>
                                        :
                                        <p className="fw-bold 4">${fullPriceSubtotalFormatted}</p>
                                        }
                                    </div>
                                    {operatingStatus.status === 'closed' ?
                                        <Card>
                                            <ListGroup variant="flush">
                                            <ListGroup.Item className={'text-center'}>
                                                {operatingStatus.reason === 'override' ? (operatingStatus.message || "We are currently closed. Please try again later.") : null}
                                                {operatingStatus.reason === 'hours' && `We are currently closed. Our normal operating hours are ${operatingStatus.open.formatted} to ${operatingStatus.close.formatted} Monday - Sunday. Please come back during our operating hours to place an order.`}
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                        <Button className="btn btn-primary w-100 mb-2"
                                                onClick={handleCheckoutClick} variant="secondary" disabled> Checkout
                                        </Button>
                                            </ListGroup.Item>
                                            </ListGroup>
                                        </Card>
                                        :
                                    <Button className="btn btn-primary w-100 mb-2"
                                            onClick={handleCheckoutClick}
                                            data-umami-event={"Checkout button"}
                                            > Checkout
                                    </Button>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    }
                </div>
            </section>
        </Container>
    )
};


export default ShoppingCartPage;
